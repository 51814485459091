
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  import PageLoader from '@/components/shared/loaders/PageLoader.vue';

  import { store } from '@/store';

  export default defineComponent({
    name: 'About',
    
    components: { 
      Header, Footer, PageLoader
    },

    data() {
      return {
        page_items: null
      }
    },
   
    created(){
      this.loadAboutUs();
    },

    methods: {
      async loadAboutUs() {
        // dispatch to About API
        await store.dispatch('aboutUsContents').then(result => {
          if(result) {
            this.page_items = result;
          }
        });
      }

    
    }
  });
