
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  export default defineComponent({
    name: 'ListLoader',
    components: { 
      Header, Footer
    }
  });
