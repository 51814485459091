
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  import ListLoader from '@/components/shared/loaders/ListLoader.vue';
  import slugify from '@/data/GeneralMixins';
  import { store } from '@/store';
    

  export default defineComponent({
    name: 'Restaurants',
    
    components: { 
      Header, Footer, ListLoader
    }, 

    mixins: [slugify],
    
    data() {
        return {
            all_restaurants: null
        }
    },

    created() {
        this.allRestaurants();
    },

    methods: {
        async allRestaurants() {
            await store.dispatch('allRestaurants').then(res => {
                if(res && res.results) {
                    this.all_restaurants = res.results;
                }
            });
        },

        restaurantURL(id: number, name: string) {
            name = name ? name : 'details';
            return '/restaurants/' + this.slugify(name) + '/' + id;
        }
    }
  });
