
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  import PageLoader from '@/components/shared/loaders/PageLoader.vue';

  import { store } from '@/store';

  export default defineComponent({
    name: 'Team',
    
    components: { 
      Header, Footer, PageLoader
    },

    data() {
      return {
        page_items: null
      }
    },
   
    created(){
      this.loadTeamContent();
    },

    methods: {
      async loadTeamContent() {
        // dispatch to our Team API
        await store.dispatch('teamContents').then(result => {
          if(result) {
            this.page_items = result;
          }
        });
      }
    }
  });
