import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store,  key } from './store';
import axios from 'axios';
import VueSocialSharing from 'vue-social-sharing';

/* Bootstrap */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"

/* Fontawesome */
import '@fortawesome/fontawesome-free/css/all.css';

/* Custom CSS */
import './assets/css/style.css';

axios.defaults.withCredentials = true

const appConfig = {
    // preset app configurations
    root: window.location.protocol + '//' + window.location.host,
    // firebaseKey: 'KEY HERE IF ANY',
    // vAPIkey: ' YOUR vAPIkey '
}

async function createApplication() {
    const app = createApp(App);
    app.provide('appConfig', appConfig);
    app.use(VueSocialSharing);
    app.use(store, key);
    app.use(router).mount('#app');
}
createApplication();