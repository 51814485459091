
  import { defineComponent } from 'vue';
  import { store } from '@/store';

  export default defineComponent({
    name: 'Footer',

    data() {
      return {
        social_links: null
      }
    },
    created(){
      this.loadFooter();
    },
    methods: {
      async loadFooter() {
        await store.dispatch('webConfiguration').then(result => {
          
          if(result) {

            this.social_links = result;
          }
        });
      
      }
    }
  });
