
  import { defineComponent } from 'vue';
  import { store } from '@/store';

  export default defineComponent({
    name: 'Footer',

    data(){
      return {
        links: [
          {
            title: 'Home',
            url: '/home',
            name: 'Home'
          },
          {
            title: 'About us',
            url: '/about-us',
          },
          {
            title: 'Restaurants',
            url: '/restaurants',
            name: 'Restaurants'
          },
          {
            title: 'Our Team',
            url: '/team',
            name: 'Team'
          },
          {
            title: ' Contact us',
            url: '/contact',
            name: 'Contact'
          }
        ],
        nav_name: 'navbar-dark bg-transparent'
      }
    },

  created () {
    window.addEventListener('scroll', this.handleScroll);
    if(this.$route.name != 'Home') {
      this.nav_name = 'navbar-light bg-light'
    }
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll (event: any) {
    // when the window is scrolled
    if(event.path && event.path.length > 0 && event.path[1].pageYOffset && event.path[1].pageYOffset > 100)
      this.nav_name = 'navbar-light bg-light shadow';
    else {
      if(this.$route.name != 'Home') {
        this.nav_name = 'navbar-light bg-light';
      } else {
        this.nav_name = 'navbar-dark bg-transparent';
      }
    }
      
  },
  async logout() {
    await store.dispatch('LogOut');
    this.$router.push({ path: 'signin', name: 'Login'});
  },
  }
  });
