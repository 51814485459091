import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '@/components/pages/auth/LoginPage.vue';
import Home from '@/components/pages/Home.vue';
import About from '@/components/pages/About.vue';
import Restaurants from '@/components/pages/Restaurants.vue'
import RestaurantDetails from '@/components/pages/restaurants/Details.vue';
import Team from '@/components/pages/Team.vue';
import Contact from '@/components/pages/Contact.vue';
import Typography from '@/components/pages/Typography.vue';
import { store } from '@/store';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/signin',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    alias: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/restaurants',
    name: 'Restaurants',
    component: Restaurants
  },
  {
    path: '/restaurants/:slug?/:id',
    name: 'RestaurantDetails',
    component: RestaurantDetails
  },
  {
    path: '/typo',
    name: 'Typography',
    component: Typography,
  }
   
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/signin");
  } else {
    next();
  }
});
export default router;
