
  import { defineComponent } from 'vue';
  import { defineRule , Form, Field, ErrorMessage } from 'vee-validate';
  import AllRules from '@vee-validate/rules';
  import { store } from '@/store';

  Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
  });

  export default defineComponent({
    name: 'ForgotPasswordModal',
    components: { 
      Form, Field, ErrorMessage
    },
    data(){
      return {
        EmailAddress: '',
        email_sent: false,
        email_fail: false
      }
    },

    methods: {
      async resetPassword () {
        // Add logic here
        this.resetForm();
      },

      resetForm() {
        this.EmailAddress = '';
        this.email_sent = false;
        this.email_fail = false;
      }
    }
  });
