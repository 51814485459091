
  import { defineComponent} from 'vue';
  import ForgotPasswordModal from '@/components/pages/auth/ForgotPasswordModal.vue';
  import { defineRule , Form, Field, ErrorMessage } from 'vee-validate';
  import AllRules from '@vee-validate/rules';
  import { store } from '@/store';


  Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
  });

  export default defineComponent({
    components: { 
      Form, Field, ErrorMessage, ForgotPasswordModal
    },
    name: 'Login',
    data() {
      return {
        errors: null,
        email: 'demo@example.com',
        password: 'demo123',
        logging: false,
        wrong_credentials: false
      }
    },
    beforeMount() {
      this.setBackground();
    },
    created() {
      this.setBackground();
    },
  
    methods: {

      async SignIn(){
        this.logging = true;
        
        let loginDetails = { email: this.email, password: this.password };

        // dispatch to userAuth action
        await store.dispatch('Login', [loginDetails]).then(res =>
          { 
            if(res) {
              this.wrong_credentials = false; // hide longin error msg if exist
            }
          }
        )
        .catch(error => {
          if(error) {
            this.logging = false;           // disable the loading in login button 
            this.wrong_credentials = true;  // show longin error msg 
          }
        }); 
    
      },

      setBackground() { 
        document.body.className = 'boarding';
      }
    }
  });
