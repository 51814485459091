
  import { defineComponent, inject } from 'vue';
  import {store} from '@/store';

  export default defineComponent({
    
    created(){
      store.commit('appConfig', inject('appConfig')); // commit appConfig to mutations.
    }
  });
