
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  import { store } from '@/store';

  export default defineComponent({
    name: 'Home',
    
    components: { 
      Header, Footer
    },

    data() {
      return {
        homepage: null as any,
        gallery: [] as any,
        other_content: null as any,
        featured_restaurants: [] as any,
        partners: [] as any,
      }
    },
   
    created(){
      this.loadHomepage();
    },

    methods: {
      async loadHomepage() {
        // dispatch to homepage API
        await store.dispatch('homePageContents').then(result => {
          if(result) {

            this.gallery = result.gallery;
            this.gallery.sort((a:any, b:any) => (a.order > b.order) ? 1 : -1);
            this.other_content = result.other_content;

            this.featured_restaurants = result.featured_restaurants;
            this.partners = result.partners;
            this.partners.sort((a:any, b:any) => (a.order > b.order) ? 1 : -1);

            // TODO: Group Partners
            let grouped = [] as any;
            let first = [];
            for(let i=0; i<this.partners.length; i++) {
              if(i < 4) {
                first.push(this.partners[i])
              }
            }
            grouped.push(first);
          }
        });
      }

    
    }
  });
