
import { defineComponent } from "vue";

export default defineComponent ({
    methods: {
        truncate (data: string, num: number){
            if(!data)
                return;
            if(data.length > num) {
                const reqString = data.split("").slice(0, num).join("");
                return reqString + '...';
            } else {
                return data
            }
        },
        slugify(text: string) {
            if(text)
                return text
                    .toLowerCase()
                    .trim()
                    .replace(/[^\w\s-]/g, '')
                    .replace(/[\s_-]+/g, '-')
                    .replace(/^-+|-+$/g, '');

        }
 
    }
});