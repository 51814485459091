
    import { defineComponent } from 'vue';
    import Header from '@/components/shared/PageHeader.vue';
    import Footer from '@/components/shared/PageFooter.vue';
    import { store } from '@/store';
    import PageLoader from '@/components/shared/loaders/PageLoader.vue';

    export default defineComponent({
        name: 'Restaurants',
        
        components: { 
            Header, Footer, PageLoader
        }, 

        data() {
            return {
                restaurant_id: null as any,
                restaurant_details: null as any,
                dine_in_hours: [] as any
            }
        },

        created() {
            this.restaurant_id = this.$route.params.id;
            if(this.restaurant_id)
                this.restaurantByID(this.restaurant_id);
        },

        methods: {
            async restaurantByID(ID: number) {
                await store.dispatch('restaurantByID', ID).then(res => {
                    if(res) {
                        this.restaurant_details = res;
                        const name = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                        const now = Date.now();
                        const DAY = 60 * 60 * 24 * 1000;
                        
                        for (let i = 0; i < 7; i++) {
                            const date = new Date(now + DAY * i);
                            // console.log(name[date.getDay()], date.getDate());
                            // TODO: Validate dates
                            let day = `${date.getDate()}/0${date.getMonth() + 1}/${date.getFullYear()}`;
                            if(this.restaurant_details && this.restaurant_details.dine_in_hours && day) {
                                let Obj =  { 
                                    date: name[date.getDay()], 
                                    hours: this.restaurant_details.dine_in_hours[day],
                                    isToday: i == 0 ? true : false
                                };
                                this.dine_in_hours.push(Obj);
                            }
                        }
                    }
                });
            }
        }

  });
