import { createStore, Store } from 'vuex'
import { InjectionKey } from 'vue'
import router from '@/router'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';
//import {AxiosError} from 'axios';
//import firebaseMessaging from '@/data/Firebase';
//import { getToken} from 'firebase/messaging';
//import Cookies from 'js-cookie';

// define typings for the store state
export interface State {
  email: string,  
  password: string, 
  loggedin: boolean
}

export interface appConfig {
  root: string,
  firebaseKey: string,
  vAPIkey: string
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();
/*
const messaging = firebaseMessaging;
const _BackendFailMessage = 'Sorry, we are unable to process your request. Please try again later!';
*/
const _TimeOut = 6000;

export const store = createStore({
  
  state: {
    appConfig: {} as appConfig,
    basePath: '',
    email: null,
    password: null,
    loggedin: false,
    homepage: null,
    configuration: null
  },

  plugins: [createPersistedState(
    {
      reducer: state => ({
        appConfig: state.appConfig,
        basePath: state.basePath,
        email: state.email,
        password: state.password,
        loggedin: state.loggedin,
        homepage: state.homepage,
        configuration: state.configuration
      }),

      /*
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { 
          expires: new Date(JSON.parse(value).token_expiry as string), 
          secure: true }
        ),
        removeItem: key => Cookies.remove(key)
      }   
      */  
  
    }
  )],

  mutations: {
    appConfig(state, appConfig) {
      state.appConfig = appConfig;
    },
   
    setUser(state, loginDetails) {
      if(loginDetails) {
        state.email = loginDetails.email;
        state.password = loginDetails.password;
      }
    },

    Logout(state) {
      state.email = null;
      state.password = null;
      state.loggedin = false;
    }
  },

  actions: {

    async Login({ state }, [loginDetails]){

      console.log(loginDetails);


      // Check user login details, in the example the login is hardcoded, 
      // please use below axios for real integration
      if(loginDetails.email == 'demo@example.com' && loginDetails.password == 'demo123') {
        // Store user details
        state.loggedin = true;
        await store.commit('setUser', loginDetails);
        router.push({ path: 'home', name: 'Home'}); 
      }

      

      /* 
        SAMPLE OF SETTING AXIOS CALL
        // to avoid proxy CORS, call the same segment name in vue.config.js
        const devBase = process.env.NODE_ENV == 'development' ? '/segmentnamehere' : ''; 
      
        const URL = state.appConfig.root + devBase + '/ YOUR PATH HERE ';
        
        const bodyParameters = {
          // Body properties
        };

          return new Promise((resolve, reject) => { 
            axios.post( URL, bodyParameters, {headers: {}, timeout: _TimeOut})
            .then(res => 
              {
                  if(res.data) {
                    // Do something
                    resolve(res.data);
                  }    
              }
            )
            .catch(error => {
              console.log(error) 
              if(error == 'Error: timeout of ' + _TimeOut + 'ms exceeded')
                alert(_BackendFailMessage); // backend error
              reject(error); 
            });
          });
      */

    },

    async LogOut({commit}){
      commit('Logout')
    },

    async homePageContents () {
      const config = {
        timeout: _TimeOut
      };
   
      const homeURL = `${process.env.VUE_APP_ROOT_API}/api/users/website/homepage/`;
      console.log(homeURL);
      return new Promise((resolve, reject) => { axios.get( homeURL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });


    },

    async webConfiguration ({ state }) {
      const config = {
        timeout: _TimeOut
      };
      const devBase = process.env.NODE_ENV == 'development' ? '/herokuapp' : '';
      const homeURL = `${state.appConfig.root}${devBase}/api/users/website/configuration/`;
      return new Promise((resolve, reject) => { axios.get( homeURL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              state.configuration = results;
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });


    },

    async aboutUsContents ({ state }) {
      const config = {
        timeout: _TimeOut
      };
      const devBase = process.env.NODE_ENV == 'development' ? '/herokuapp' : '';
      const homeURL = `${state.appConfig.root}${devBase}/api/users/website/about_us/`;
      return new Promise((resolve, reject) => { axios.get( homeURL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async teamContents ({ state }) {
      const config = {
        timeout: _TimeOut
      };
      const devBase = process.env.NODE_ENV == 'development' ? '/herokuapp' : '';
      const homeURL = `${state.appConfig.root}${devBase}/api/users/website/team/`;
      return new Promise((resolve, reject) => { axios.get( homeURL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    /** Restaurants */
    async allRestaurants ({ state }) {
      const config = {
        timeout: _TimeOut
      };
      const devBase = process.env.NODE_ENV == 'development' ? '/herokuapp' : '';
      const homeURL = `${state.appConfig.root}${devBase}/api/users/restaurants/all/`;
      return new Promise((resolve, reject) => { axios.post( homeURL, {}, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async restaurantByID ({ state }, ID) {
      const config = {
        timeout: _TimeOut
      };
      const devBase = process.env.NODE_ENV == 'development' ? '/herokuapp' : '';
      const homeURL = `${state.appConfig.root}${devBase}/api/users/restaurants/${ID}/`;
      return new Promise((resolve, reject) => { axios.get( homeURL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },


  },
  getters: {
    // user is Authenticated once the loggedin is retrieved
    isAuthenticated: (state) => !!state.loggedin,
  }
})